<template>
  <div id="app" class="mt-2_ font-sans">
    <div id="top-bar" class="sticky_ top-0_ bg-gray-900 z-50 shadow-lg">
      <div class="flex flex-row items-center text-lg leading-normal py-3 md:py-2 px-2 sm:px-3  ">
        <div class="flex items-center">
          <!--<router-link class="text-showman no-underline text-2xl flex flex-start pb-1 md:pb-2" to="/">
            <img src="https://res.cloudinary.com/showman/image/upload/c_fill,g_auto,h_65,q_auto,f_auto/v1576355554/logo_smooth_alt_new.png" class="h-10 md:h-16" alt="Showman">
          </router-link>-->
          <a class="hidden md:block text-showman no-underline text-2xl flex flex-start pb-1" href="/">
            <img src="https://res.cloudinary.com/showman/image/upload/c_fill,g_auto,h_65,q_auto,f_auto/v1576355554/logo_smooth_alt_new.png" class="h-10 md:h-16" alt="Showman">
          </a>
          <a class="block md:hidden text-showman no-underline text-2xl flex flex-start" href="/">
            <img src="https://res.cloudinary.com/showman/image/upload/h_65,f_auto/v1671228923/Showman-Banner-Orange.png" class="h-10" alt="Showman">
          </a>
        </div>

        <div class="flex flex-grow items-center font-headline font-sans justify-end text-center">
          <!--<router-link :to="'pricing'" class="py-2 px-1 md:px-3 rounded text-white text-xs md:text-base no-underline hover:text-showman">Pricing</router-link>-->
          <!--<a onclick="fathom.trackGoal('KOKIW9BU', 0);" href="/#/pricing" class="py-2 px-1 md:px-3 rounded text-white text-xs md:text-base no-underline hover:text-showman">Pricing</a>-->
          <a class="py-0.5 md:py-1 px-2 md:px-3 text-white no-underline hover:bg-white hover:text-black border border-white rounded-md md:text-xl mr-2" href="/shows">Find Shows</a>
          <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" class="py-0.5 md:py-1 px-2 md:px-3 text-white no-underline hover:bg-black rounded-md text-base md:text-xl" href="/shows/#/new-show-signup">Create a Show</a>
          <a onclick="fathom.trackGoal('KOKIW9BU', 0);" href="/#/pricing" class="py-0.5 md:py-1 px-2 md:px-3 text-white no-underline hover:bg-black rounded-md text-base md:text-xl">Pricing</a>
          <!--a onclick="fathom.trackGoal('PPBJ1VUV', 0);" class="flex-0 btn-sm text-xs  md:text-lg bg-showman text-white ml-1" href="/shows/#/new-show-signup">Create a Show</a>-->
        </div>
      </div>
      <div class="relative bg-white border-t-4 border-b-4 border-showman" v-if="showAnnouncement">
        <div class="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
          <div class="pr-16 sm:px-16 sm:text-center">
            <p class="font-medium text-black">
              <span class="md:hidden font-bold">📣 Introducing Showman Auctions!</span>
              <span class="hidden md:inline font-bold">📣 Introducing Showman Auctions!</span>
              <svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>
              <span>Now use Showman to run your Auction. A great option for any sale that is held in conjunction with a show!</span>
              <span class="block sm:ml-2 sm:inline-block">
                <a href="/#/pricing#auction" class="font-bold text-black underline">Visit Pricing to Learn More <span aria-hidden="true">&rarr;</span></a>
              </span>
            </p>
          </div>
          <div class="absolute inset-y-0 right-0 flex items-start pr-1 pt-1 sm:items-start sm:pr-2 sm:pt-1">
            <button type="button" class="flex rounded-md p-2 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white" @click="showAnnouncement = false">
              <span class="sr-only">Dismiss</span>
              <svg class="h-6 w-6 text-gray-900" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <!--<div class="mx-auto max-w-7xl px-3 py-3 sm:px-6 lg:px-8">
          <div class="pr-16 sm:px-16 sm:text-center">
            <p class="font-medium text-black">
              <span class="md:hidden font-bold">📣 Showman is hiring!</span>
              <span class="hidden md:inline font-bold">📣 Showman is hiring!</span>
              <svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>
              <span>We are looking for energetic go getters, leaders, self-starters to join our Showman team. </span>
              <span class="block sm:ml-2 sm:inline-block">
                <a href="https://jobs.gusto.com/boards/showman-61028a22-fcff-4867-af5a-ae6e5fab9dbd" target="_blank" class="font-bold text-black underline">Learn more <span aria-hidden="true">&rarr;</span></a>
              </span>
            </p>
          </div>
          <div class="absolute inset-y-0 right-0 flex items-start pr-1 pt-1 sm:items-start sm:pr-2 sm:pt-1">
            <button type="button" class="flex rounded-md p-2 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white" @click="showAnnouncement = false">
              <span class="sr-only">Dismiss</span>
              <svg class="h-6 w-6 text-gray-900" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>-->
      </div>
    </div>

    <!-- Top Section to announce something or for a contest-->
    <!--<div style="background-color:#004c97" class="">
      <div v-if="show_giveaway" class="text-white container mx-auto flex flex-col-reverse md:flex-row justify-center md:space-x-4 items-center">
        <div class="max-w-md px-4 md:px-0 py-4 md:py-0">
          <h2 class="text-3xl md:text-4xl font-medium mb-1" style="color:#F2CE1B">Showman ❤️'s FFA</h2>
          <p>In celebration of the 2022 National FFA Convention, Showman is giving away over $3000 in prize!</p>
          <div class="mt-6 flex justify-between">
            <a class="border rounded-lg text-lg py-2 px-3 hover:bg-white hover:text-black" href="/ffa">Click here to Enter</a>
            <button class="hover:underline hover:pointer ml-3 text-sm" @click="show_giveaway=false">Hide</button>
          </div>
        </div>
        <img src="https://res.cloudinary.com/showman/image/upload/w_450,f_auto/v1666722989/Pig_2.png">
      </div>
      <div v-else class="text-center text-white py-2 text-sm flex flex-col md:flex-row justify-center items-center">
        <a class="underline hover:pointer" href="/ffa">Showman Blue & Gold Giveaway ~ Oct. 26th - Oct. 29th</a>
        <button class="border rounded-lg text-xs py-1 px-2 hover:bg-white hover:text-black mt-1 md:mt-0 ml-0 md:ml-2" @click="show_giveaway=true">Learn More</button>
      </div>
    </div>-->
    <router-view class=""/>
    <footer class="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div class="lg:grid lg:grid-cols-3 lg:gap-8">
          <div class="space-y-8 lg:col-span-1">
            <img class="h-12" src="https://res.cloudinary.com/showman/image/upload/c_fill,g_auto,h_65,q_auto,f_auto/v1631719458/showman_logo_black.png" alt="Company name">
            <h4 class="pb-3 text-3xl font-thin">Showman Newsletter</h4>
            <iframe src="https://embeds.beehiiv.com/e27d8080-462b-4d61-b329-76f60e8eae25?slim=true" data-test-id="beehiiv-embed" height="52" frameborder="0" scrolling="no" style="margin: 0; border-radius: 0px !important; background-color: transparent;"></iframe>
            <p class="text-gray-500 text-base">
              Online show management software built for livestock shows. Running your livestock show will never be the same.
            </p>
            <div class="flex space-x-6">
              <a href="https://www.facebook.com/ShowmanLivestock" class="text-gray-400 hover:text-gray-500">
                <span class="sr-only">Facebook</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
                </svg>
              </a>
              <a href="https://www.instagram.com/showmanlivestock/" class="text-gray-400 hover:text-gray-500">
                <span class="sr-only">Instagram</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                </svg>
              </a>
              <!--

              <a href="#" class="text-gray-400 hover:text-gray-500">
                <span class="sr-only">Twitter</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>

              <a href="#" class="text-gray-400 hover:text-gray-500">
                <span class="sr-only">GitHub</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
                </svg>
              </a>

              <a href="#" class="text-gray-400 hover:text-gray-500">
                <span class="sr-only">Dribbble</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" />
                </svg>
              </a>
              -->
            </div>
          </div>
          <div class="mt-12 md:grid md:grid-cols-3 md:gap-8 lg:mt-0 lg:col-span-2">
            <!--<div class="md:grid md:grid-cols-2 md:gap-8">-->
              <div>
                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  Product
                </h3>
                <ul role="list" class="mt-4 space-y-4">
                  <li>
                    <!--<router-link to="/" class="text-base text-gray-600 hover:text-gray-900" @click.native="$scrollToTop">
                      Home
                    </router-link>-->
                    <a href="/" class="text-base text-gray-600 hover:text-gray-900">
                      Home
                    </a>
                  </li>

                  <li>
                    <!--<router-link to="/pricing" class="text-base text-gray-600 hover:text-gray-900" @click.native="$scrollToTop">
                      Pricing
                    </router-link>-->
                    <a onclick="fathom.trackGoal('KOKIW9BU', 0);" href="/#/pricing" class="text-base text-gray-600 hover:text-gray-900">
                      Pricing
                    </a>
                  </li>

                  <li>
                    <!--<router-link to="/request-a-demo" class="text-base text-gray-600 hover:text-gray-900" @click.native="$scrollToTop">
                      Request a demo
                    </router-link>-->
                    <a onclick="fathom.trackGoal('HIJDFCOU', 0);" href="/#/request-a-demo" class="text-base text-gray-600 hover:text-gray-900">
                      Request a demo
                    </a>
                  </li>

                  <li>
                    <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="text-base text-gray-600 hover:text-gray-900">
                      Create a show
                    </a>
                  </li>

                  <li>
                    <a href="/shows/#/show-series" class="text-base text-gray-600 hover:text-gray-900">
                      Show series
                    </a>
                  </li>

                  <li>
                    <a href="/#/pricing" class="text-base text-gray-600 hover:text-gray-900">
                      Auctions (View Pricing)
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mt-12 md:mt-0">
                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  Support
                </h3>
                <ul role="list" class="my-4 space-y-4">
                  <li>
                    <!--<router-link to="/case-study-new-show" class="text-base text-gray-600 hover:text-gray-900" @click.native="$scrollToTop">
                      New show case study
                    </router-link>-->
                    <a href="/#/case-study-new-show" class="text-base text-gray-600 hover:text-gray-900">
                      New show case study
                    </a>
                  </li>
                  <li>
                    <a href="https://showman.helpscoutdocs.com/" class="text-base text-gray-600 hover:text-gray-900">
                      Knowledge base
                    </a>
                  </li>

                  <!--<li>
                    <router-link to="#FAQ" class="text-base text-gray-600 hover:text-gray-900">
                      FAQ's
                    </router-link>
                  </li>-->

                  <li>
                    <a href="mailto:support@showman.app" target="_blank" class="text-base text-gray-600 hover:text-gray-900">
                      Email support
                    </a>
                  </li>

                  <!--<li>
                    <a href="#" class="text-base text-gray-600 hover:text-gray-900">
                      API Status
                    </a>
                  </li>-->

                </ul>
                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  Species
                </h3>
                <ul role="list" class="my-4 space-y-4">
                  <li>
                    <!--<router-link to="/goat-show" class="text-base text-gray-600 hover:text-gray-900" @click.native="$scrollToTop">
                      Goat Jackpot Shows
                    </router-link>-->
                    <a href="/species/goats" class="text-base text-gray-600 hover:text-gray-900">
                      Goat Jackpot Shows
                    </a>
                  </li>
                  <li>
                    <!--<router-link to="/cattle-show" class="text-base text-gray-600 hover:text-gray-900" @click.native="$scrollToTop">
                      Cattle Jackpot Shows
                    </router-link>-->
                    <a href="/species/cattle" class="text-base text-gray-600 hover:text-gray-900">
                      Cattle Jackpot Shows
                    </a>
                  </li>
                  <li>
                    <!--<router-link to="/hog-show" class="text-base text-gray-600 hover:text-gray-900" @click.native="$scrollToTop">
                      Hog Jackpot Shows
                    </router-link>-->
                    <a href="/species/hogs" class="text-base text-gray-600 hover:text-gray-900">
                      Hog Jackpot Shows
                    </a>
                  </li>
                  <li>
                    <!--<router-link to="/sheep-show" class="text-base text-gray-600 hover:text-gray-900" @click.native="$scrollToTop">
                      Sheep Jackpot Shows
                    </router-link>-->
                    <a href="/species/sheep" class="text-base text-gray-600 hover:text-gray-900">
                      Sheep Jackpot Shows
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mt-12 md:mt-0">
                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  Company
                </h3>
                <ul role="list" class="my-4 space-y-4">
                  <li>
                    <!--<router-link to="/about" class="text-base text-gray-600 hover:text-gray-900" @click.native="$scrollToTop">
                      About
                    </router-link>-->
                    <a href="/#/about" class="text-base text-gray-600 hover:text-gray-900">
                      About
                    </a>
                  </li>

                  <li>
                    <a href="/privacy_policy.html" target="_blank" class="text-base text-gray-600 hover:text-gray-900">
                      Privacy policy
                    </a>
                  </li>

                  <li>
                    <a href="/terms_of_service.html" target="_blank" class="text-base text-gray-600 hover:text-gray-900">
                      Terms of service
                    </a>
                  </li>
                </ul>
                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  Exhibitors & Supporters
                </h3>
                <ul role="list" class="my-4 space-y-4">
                  <li>
                    <a href="/shows" class="text-base text-gray-600 hover:text-gray-900">
                      Enter a show
                    </a>
                  </li>
                  <li>
                    <a href="/shows/#/show-series" class="text-base text-gray-600 hover:text-gray-900">
                      Join a show series
                    </a>
                  </li>

                  <li>
                    <a href="/shows/#/sponsor-a-show" class="text-base text-gray-600 hover:text-gray-900">
                      Sponsor a show
                    </a>
                  </li>

                  <!--<li>
                    <a href="#" class="text-base text-gray-600 hover:text-gray-900">
                      Terms
                    </a>
                  </li>-->
                </ul>
              </div>
            <!--</div>-->
          </div>
        </div>
        <div class="mt-12 border-t border-gray-200 pt-8">
          <p class="text-base text-gray-400 lg:text-center">
            &copy; {{new Date().getFullYear()}} Maynetainable, LLC
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show_giveaway: false,
      showAnnouncement: true
    }
  },
  methods: {
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style>
body {
  background: #f6993f;
}
.newsletter .ml-form-embedBody {
  padding: 0px 0px 0px 0px !important;
}
</style>
